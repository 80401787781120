<template>
  <div class="grid" :class="columnClasses">
    <TafeTile v-for="(tile, i) in tiles" :key="i" v-bind="tile"></TafeTile>
  </div>
</template>

<script>
export default {
  name: 'TafeTiles',
  props: {
    tiles: {
      type: Array,
      required: true
    },
    columns: {
      type: Number,
      default: 4
    }
  },
  computed: {
    columnClasses() {
      return `grid-cols-2 md:grid-cols-${Math.ceil(
        this.columns / 2
      )} lg:grid-cols-${this.columns}`
    }
  }
}
</script>
